import React, { useEffect, useRef, useState } from "react"
import CheckoutModal from "@components/checkout/CheckoutModal"
import MoneybackPolicyLink from "@components/checkout/MoneybackPolicyLink"
import PlanInfo from "@components/checkout/PlanInfo"
import RenewalDisclaimer from "@components/checkout/RenewalDisclaimer"
import { Button } from "@components/common/Button"
import { User } from "@contexts/auth"
import { usePayment } from "@contexts/payment"
import { SurveyTheme } from "@contexts/survey/survey"
import { PayPalScriptProvider } from "@paypal/react-paypal-js"
import * as events from "@services/client/events"
import { getStripeAccount } from "@services/client/stripe"
import { getCLVinUSD, getPricingTable, Pricing, PricingTable, Store } from "@services/pricing"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"

import { useTranslation } from "../../i18n"
import AbortOfferInfo from "./AbortOfferInfo"
import AppStoreRating from "./AppStoreRating"
import CheckoutAbortModal from "./CheckoutAbortModal"
import CheckoutCard from "./CheckoutCard"
import DividerPaywall from "./DividerPaywall"
import InsuranceDisclaimer from "./InsuranceDisclaimer"
import InsuranceInfo from "./InsuranceInfo"
import Moneyback from "./Moneyback"
import OfferCountdown from "./OfferCountdown"
import PaymentOptionsList from "./PaymentOptionsList"
import PricingList from "./PricingList"
import Reviews from "./Reviews"
import WhatYouGetSection from "./WhatYouGetSection"

const paypalLocales = {
  en: "en_US",
  de: "de_DE",
  es: "es_ES",
  fr: "fr_FR",
  it: "it_IT",
  pt: "pt_PT",
}

const Paywall: React.FC<{
  user: User
  theme: SurveyTheme
  funnel: string
  currency: string
  countryCode: string
  withPlanInfo: boolean
  pricingTable?: PricingTable
  pricingTableAbort?: PricingTable
  onCheckoutComplete: (method: Store, price: Pricing, data: any) => Promise<void>
  onCheckoutToInsurance: () => void
  disableInsurance?: boolean
}> = ({
  user,
  theme,
  funnel,
  currency,
  countryCode,
  withPlanInfo,
  pricingTable,
  pricingTableAbort,
  onCheckoutComplete: onCheckoutCompleteParent,
  onCheckoutToInsurance,
  disableInsurance = false,
}) => {
  const { t, i18n } = useTranslation()
  const { setPurchase } = usePayment()

  // Refs
  const checkoutCardRef = useRef(null)

  // Experiment variants
  const checkoutVariant = "modal" as "modal" | "card"

  const insuranceEnabled = i18n.language === "de" && !disableInsurance

  // Pricing related
  const stripeAccount = getStripeAccount(user.id)
  const [pricingTableVariant, setPricingTableVariant] = useState<string>("default")
  const defaultPricingTable = pricingTable ?? getPricingTable(stripeAccount.name, "default", currency)
  const abortPricingTable = pricingTableAbort ?? getPricingTable(stripeAccount.name, "abort", currency)
  const activePricingTable = pricingTableVariant === "abort" ? abortPricingTable : defaultPricingTable
  const isAbortOffer = pricingTableVariant === "abort"
  const [selectedPrice, setSelectedPrice] = useState<Pricing>(
    insuranceEnabled ? null : defaultPricingTable.pricings.find((p) => p.is_preselected)
  )
  const [insuranceSelected, setInsuranceSelected] = useState(insuranceEnabled)

  // UI state
  const [openCheckout, setOpenCheckout] = useState<boolean>(false)
  const [openCheckoutAbort, setOpenCheckoutAbort] = useState<boolean>(false)
  const [countdownEnd, setCountdownEnd] = useState(
    Number(localStorage.getItem("countdown_end")) || Date.now() + 60000 * 15
  )

  const handleInsuranceSelect = () => {
    setInsuranceSelected(true)
    setSelectedPrice(null)
  }

  useEffect(() => {
    const nextSelectedPrice = activePricingTable.pricings.find((p) => p.is_preselected)
    setSelectedPrice(nextSelectedPrice)
    events.trackPricingTableSeen(nextSelectedPrice, activePricingTable.id, stripeAccount.name, checkoutVariant)
  }, [pricingTableVariant])

  useEffect(() => {
    localStorage.setItem("countdown_end", countdownEnd.toString())
    if (countdownEnd < Date.now()) {
      setCountdownEnd(Date.now() + 60000 * 15)
    }
  }, [countdownEnd])

  const onPriceChange = (price: Pricing) => {
    setSelectedPrice(price)
    setInsuranceSelected(false)
    events.trackPriceSelected(price, activePricingTable.id)
  }

  const onStartPlanClick = () => {
    // If insurance is selected, trigger insurance checkout
    if (insuranceSelected) {
      onCheckoutToInsurance()
      return
    }

    if (checkoutVariant === "modal") {
      setOpenCheckout(true)
      events.trackCheckoutOpen(selectedPrice, activePricingTable.id)
    } else if (checkoutVariant === "card") {
      checkoutCardRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }

  const onCheckoutClose = () => {
    if (checkoutVariant === "modal") {
      setOpenCheckout(false)
      events.trackCheckoutClose(selectedPrice, activePricingTable.id)
      if (!isAbortOffer) {
        setOpenCheckoutAbort(true)
      } else {
        // track pricing table seen again after checkout is closed
        // not required if abort flow should be shown
        // because it gets triggered if pricing variant gets changed
        events.trackPricingTableSeen(selectedPrice, activePricingTable.id, stripeAccount.name, checkoutVariant)
      }
    }
  }

  const onCheckoutComplete = async (method: Store, price: Pricing, data: any) => {
    setPurchase(method, price, activePricingTable.id)
    events.trackPurchase({
      uid: user.id,
      provider: method,
      price,
      pricing_variant: activePricingTable.id,
      checkout_variant: checkoutVariant,
      clv_usd: getCLVinUSD(method, price, countryCode),
    })

    onCheckoutCompleteParent(method, price, data)
  }

  const onCheckoutStart = (method: Store, price: Pricing) => {
    events.trackInitiateCheckout(user.id, method, price, activePricingTable.id, checkoutVariant)
  }

  const onCheckoutCancel = (method: Store, price: Pricing) => {
    events.trackCheckoutCanceled(user.id, method, price, activePricingTable.id, checkoutVariant)
  }

  const onCheckoutError = (method: Store, price: Pricing, error: any) => {
    console.error(error)
    events.trackCheckoutError(user.id, method, price, activePricingTable.id, error, checkoutVariant)
  }

  const onCheckoutAbortClose = () => {
    if (funnel !== "trial_upgrade") {
      setPricingTableVariant("abort")
      setCountdownEnd(Date.now() + 60000 * 10)
    }
    setOpenCheckoutAbort(false)
  }

  const [stripePromise] = useState(() => loadStripe(stripeAccount.apiKey))

  return (
    <PayPalScriptProvider
      options={{
        clientId: process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID,
        currency: currency,
        vault: true,
        intent: "subscription",
        locale: paypalLocales[i18n.language],
      }}
    >
      <Elements stripe={stripePromise}>
        <div className="max-w-lg w-full mx-auto px-4">
          {/* <div ref={scrollRef} /> */}

          <>
            <h2 className="text-3xl text-center font-black text-dark1 mb-4 mt-4">
              {t("survey:program.title", { context: theme })}
            </h2>
          </>

          {withPlanInfo && <PlanInfo />}

          {isAbortOffer && selectedPrice && (
            <AbortOfferInfo
              oldPrice={defaultPricingTable.pricings.find((p) => p.price === selectedPrice.price)}
              newPrice={selectedPrice}
              theme={theme}
            />
          )}

          <OfferCountdown countdownEnd={countdownEnd} />

          {insuranceEnabled && (
            <>
              <InsuranceInfo
                onCheckoutToInsurance={onCheckoutToInsurance}
                variant={"updated"}
                isSelected={insuranceSelected}
                onSelect={handleInsuranceSelect}
              />
            </>
          )}

          {!insuranceEnabled && (
            <>
              <PricingList
                pricings={activePricingTable.pricings}
                selectedPrice={selectedPrice}
                onSelect={onPriceChange}
                showMostPopular={!insuranceSelected}
                showWeekTrial={funnel === "trial_upgrade"}
              />
              <PaymentOptionsList />

              <MoneybackPolicyLink />
            </>
          )}

          <Button
            primary
            label={t("common:controls.continue")}
            className="w-full text-xl tracking-wider mb-4 uppercase animate-pulsar"
            onClick={onStartPlanClick}
          />

          {selectedPrice && !insuranceSelected && <RenewalDisclaimer price={selectedPrice} />}

          {insuranceSelected && <InsuranceDisclaimer />}

          {/* <div className="mt-5">
                        <LottieGraph2 />
                    </div> */}

          {/* <div className="mt-10">
                        <AppPreviewSlider theme={theme} />
                    </div> */}

          <div className="mt-6 mb-6">
            <AppStoreRating />
          </div>

          <div className="mt-12 mb-6">
            <WhatYouGetSection theme={theme} />
          </div>

          <Button
            primary
            label={t("common:controls.continue")}
            className="w-full text-xl tracking-wider mb-4 uppercase animate-pulsar"
            onClick={onStartPlanClick}
          />

          <div className="mt-12">
            <Reviews />
          </div>

          {checkoutVariant === "modal" && (
            <>
              <h2 className="text-3xl text-center font-black text-dark1 mb-4 mt-4">{t("survey:program.subtitle")}</h2>

              {withPlanInfo && <PlanInfo />}

              <OfferCountdown countdownEnd={countdownEnd} />

              {insuranceEnabled && (
                <>
                  <InsuranceInfo
                    onCheckoutToInsurance={onCheckoutToInsurance}
                    variant={"updated"}
                    isSelected={insuranceSelected}
                    onSelect={handleInsuranceSelect}
                  />
                  <DividerPaywall />
                </>
              )}

              {!insuranceEnabled && (
                <>
                  <PricingList
                    pricings={activePricingTable.pricings}
                    selectedPrice={selectedPrice}
                    onSelect={onPriceChange}
                    showMostPopular={!insuranceSelected}
                    showWeekTrial={funnel === "trial_upgrade"}
                  />
                  <PaymentOptionsList />

                  <MoneybackPolicyLink />
                </>
              )}

              <Button
                primary
                label={t("common:controls.continue")}
                className="w-full text-xl tracking-wider mb-4 uppercase animate-pulsar"
                onClick={onStartPlanClick}
              />

              {selectedPrice && !insuranceSelected && <RenewalDisclaimer price={selectedPrice} />}
            </>
          )}

          {checkoutVariant === "card" && (
            <div ref={checkoutCardRef}>
              <OfferCountdown countdownEnd={countdownEnd} />

              <h3 className="text-lg text-left font-bold text-dark1 mb-4 mt-4">
                {t("survey:program.form.payment_method")}
              </h3>

              <div>
                <CheckoutCard
                  user={user}
                  price={selectedPrice}
                  countryCode={countryCode}
                  onCheckoutComplete={onCheckoutComplete}
                  onCheckoutStart={onCheckoutStart}
                  onCheckoutCancel={onCheckoutCancel}
                  onCheckoutError={onCheckoutError}
                />
              </div>
            </div>
          )}

          <div className="mt-4">
            <Moneyback />
          </div>
        </div>
        {openCheckout && (
          <CheckoutModal
            user={user}
            price={selectedPrice}
            countryCode={countryCode}
            onCheckoutComplete={onCheckoutComplete}
            onCheckoutStart={onCheckoutStart}
            onCheckoutCancel={onCheckoutCancel}
            onCheckoutError={onCheckoutError}
            onClose={onCheckoutClose}
            fakeTrial={funnel === "trial_upgrade"}
          />
        )}
        {openCheckoutAbort && <CheckoutAbortModal onClose={onCheckoutAbortClose} />}
      </Elements>
    </PayPalScriptProvider>
  )
}

export default Paywall
